<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div class="uk-background-default breadcrumb" style="padding: 15px 35px; border-bottom: 1px solid rgb(229, 229, 229)">
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h5 uk-margin-remove">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              {{ headerLabel }}
            </h3>
          </div>
          <div>
            <a href="#" @click.prevent="$router.go(-1)" class="uk-button uk-button-text">Schließen</a>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-container-expand">
      <div class="uk-section uk-section-xsmall uk-margin-large-bottom">
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-1-4@l">
            <h4 class="uk-margin-small">Daten</h4>
            <div class="uk-text-muted uk-text-small">Die Daten der Versicherung</div>
          </div>
          <div class="uk-width-expand">
            <ValidationObserver ref="observer">
              <div class="uk-card uk-card-default">
                <div class="uk-card-body">
                  <div class="uk-width-xlarge@l">
                    <form class="uk-form-stacked" @submit.prevent="handleSubmit">
                      <div class="uk-grid uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Name*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider :rules="'required'" name="name" v-slot="{ errors }">
                              <input class="uk-input uk-border-rounded" type="text" v-model="form.name" :class="{ 'uk-form-danger': errors[0] }" />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Straße*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider :rules="'required'" name="street" v-slot="{ errors }">
                              <input class="uk-input uk-border-rounded" type="text" v-model="form.street" :class="{ 'uk-form-danger': errors[0] }" />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">PLZ*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider :rules="'required'" name="zipcode" v-slot="{ errors }">
                              <input class="uk-input uk-border-rounded" type="text" v-model="form.zipcode" :class="{ 'uk-form-danger': errors[0] }" />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Ort*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider :rules="'required'" name="city" v-slot="{ errors }">
                              <input class="uk-input uk-border-rounded" type="text" v-model="form.city" :class="{ 'uk-form-danger': errors[0] }" />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">E-Mail</label>
                          <div class="uk-form-controls">
                            <input class="uk-input uk-border-rounded" type="text" v-model="form.email" />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Telefon</label>
                          <div class="uk-form-controls">
                            <input class="uk-input uk-border-rounded" type="text" v-model="form.phone" />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Fax</label>
                          <div class="uk-form-controls">
                            <input class="uk-input uk-border-rounded" type="text" v-model="form.fax" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="uk-card-footer">
                  <div class="uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-right" type="submit" :disabled="sending" @click.prevent="handleSubmit(true)">Speichern und Schließen</button>
                    <button class="uk-button uk-button-primary uk-border-rounded" type="submit" :disabled="sending" @click.prevent="handleSubmit(false)">Speichern</button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InsuranceService from '@/services/insurance.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Pflichtfeld',
})

export default {
  data() {
    return {
      sending: false,
      form: {
        id: null,
        name: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        phone: '',
        fax: '',
      },
    }
  },
  mounted() {
    if (typeof this.$route.params.id !== 'undefined') {
      this.getInsurance(this.$route.params.id)
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    headerLabel() {
      let label = 'Versicherung erstellen'
      if (this.form.id) {
        label = this.form.name
      }
      return label
    },
  },
  methods: {
    getInsurance(id) {
      InsuranceService.get(id).then((response) => {
        this.form = response.data
      })
    },
    async handleSubmit(close) {
      const observer = this.$refs.observer
      const success = await observer.validate()

      if (!success) {
        for (const key of Object.keys(observer.fields)) {
          if (observer.fields[key].invalid) {
            window.scroll({ top: observer.refs[key].$el.offsetTop - 50, left: 0, behavior: 'smooth' })
            return
          }
        }
      }

      this.store(close)
    },
    async store(close) {
      this.sending = true
      let response = null
      if (this.form.id) {
        try {
          response = await InsuranceService.update(this.form.id, this.form)
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          response = await InsuranceService.store(this.form)
        } catch (error) {
          console.log(error)
        }
      }

      if (response) {
        this.getInsurance(response.data.id)
      }

      this.sending = false

      iziToast.success({
        title: 'OK',
        message: 'Versicherung gespeichert!',
        timeout: 3000,
        position: 'bottomRight',
        transitionIn: 'fadeInLeft',
      })

      if (close) {
        this.$router.push({
          name: 'insurance',
        })
      }
    },
  },
}
</script>
